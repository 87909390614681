.hire-us-container{
    display: flex;
    justify-content: space-between;
    margin-bottom: 248px;
}

.hire-us-text-container{
    flex-basis: 931px;
}

.hire-us-title{
    margin-bottom: 106px;
}

.hire-us-title-mobile{
    display: none;
}

.hire-us-img{
    border: 2px solid var(--pink);
    border-radius: 49% 50% 0% 0% / 35% 35% 46% 45% ;
    padding: 20px;
}

.hire-us-text{
    font-size: 50px;
    letter-spacing: 2.5px;
    color: var(--pink);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    margin-bottom: 100px;
    margin-top: 331px;
}

.hire-us-lt-title{
    text-align: left !important;
    cursor: pointer;
}