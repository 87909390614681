@media only screen and (max-width: 1440px) {
    .looking-for-img{
        width: 345px;
        height: 629px;
    }
    .looking-for-title{
        font-size: 40px;
        margin-bottom: 90px;
    }
    .looking-for-text-container{
        padding-top: 100px;
    }
    .looking-for-text{
        font-size: 30px;
        max-width: 650px;
    }
    .looking-for-lets-talk-text{
        font-size: 40px  !important;
    }
}


@media only screen and (max-width: 1280px) {
    .looking-for-container{
        justify-content: center;
        flex-wrap: wrap;
    }
    .looking-for-lets-talk-text{
        display: none;
    }
    .looking-for-title{
        text-align: center !important;
        white-space: normal;
    }
    .looking-for-text{
        text-align: center !important;
        max-width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .looking-for-container{
        background-color: var(--page-second-bg);
        padding-bottom: 50px;
        margin-bottom: 0;
        padding-top: 70px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .looking-for-text-container{
        padding-top: 0;
    }
    .looking-for-img{
        width: 168px;
        height: 281px;
        padding: 5px;
    }
    .looking-for-text{
        font-size: 20px;
        letter-spacing: 0.4px;
        margin-bottom: 50px !important;
    }
    .lf-mb-points{
        display: inline;
        margin-bottom: 40px !important;
    }
    .lf-dk-points{
        display: none;
    }
    .looking-for-title{
        margin-bottom: 0px;
    }
    .looking-for-text-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .looking-for-text-mb{
        display: inline;
    }
    .looking-for-text-dk{
        display: none;
    }
    .looking-for-text-mb-s::before { content: '\A'; white-space: pre;}
    .looking-for-text-mb-s{
        display: inline;
        margin-bottom: 74px !important;
    }
    .looking-for-title{
        font-size: 28px !important;
    }
}