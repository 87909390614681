.we-are-container{
    display: flex ;
    align-items: center;
    flex-direction: column;
}

.we-are-title{
    color: var(--green);
    font-size: 48px;
    letter-spacing: 2.4px;
    font-family: 'ManhattanRegular';
    text-align: center;
}

.subtitle{
    color: var(--pink);
    font-size: 60px;
    letter-spacing: 3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-align: center;
}

.normal-text{
    color:var(--pink);
    font-family: 'ManhattanRegular';
    letter-spacing: 1.3px;
    font-size: 26px;
}

.subtitle-container-mobile{
    display: none;
}

.subtitle-container{
    display: block;
}
