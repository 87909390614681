.regular-text{
    color: var(--pink);
    font-size: 26px;
    letter-spacing: 1.3px;
    margin: 0;
    white-space: nowrap;
    font-family: "ManhattanRegular";
    text-transform: uppercase;
}

.coming-soon-container{
    background-color: #F7F3F2;
    width: 100vw;
    min-height: 100vh;
}

.top-container{
    width: 100vw;
    margin-bottom: 263px;
    display: flex;
    justify-content: center;
    padding-top: 51px;
}

.main-container{
    width: 100vw;
    display: flex;
    flex-direction: row;
}

.left-container{
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-text{
    font-size: 28px !important;
    letter-spacing: 1.4px;
}

.main-text-up{
    font-size: 56px !important;
    letter-spacing: 8.4px;
}
.main-text-down{
    font-size: 86px !important;
    text-align: center;
    letter-spacing: 12.9px;
    line-height: 100px;
}

.center-container{
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-container{
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-text-container{
    margin-bottom: 157px;
}

.image-container{
    padding-bottom: 27px;
    display: flex;
    justify-content: center;
}

.title-container > p{
    text-align: center;
    color:black;
}

.title-container{
    margin-bottom: 32px;
}

.point-text{
    font-size: 56px;
    letter-spacing: 10px;
}

.studio-text{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    letter-spacing: 8px;
}

.left-section{
    position: absolute;
    top: 517px;
}

.right-section{
    position: absolute;
    top: 518.5px;
}