@media only screen and (max-width: 1440px) {
    .hire-us-title{
        margin-bottom: 90px;
        white-space: nowrap;
        font-size: 40px;
        text-align: left !important;
    }
    .hire-us-img{
        max-width: 70%;
    }
    .hire-us-text-container{
        flex-basis: 600px;
    }
    .hire-us-text{
        font-size: 30px;
        margin-bottom: 100px;
        margin-top: 231px;
    }
    .hire-us-lt-title{
        font-size: 40px;
    }
}

@media only screen and (max-width: 1280px) {
    .hire-us-container{
        flex-wrap: wrap;
        justify-content: center;
    }
    .hire-us-text{
        margin-top: 0px;
    }
    .hire-us-img-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .hire-us-img{
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .hire-us-text{
        text-align: center;
    }
}

@media only screen and (max-width: 700px) {
    .hire-us-img{
        display: none;
    }
    .hire-us-title-elipsis{
        display: none;
    }
    .hire-us-title{
        margin-bottom: 0;
    }
    .hire-us-title-mobile{
        display: inline;
    }
    .hire-us-img-container{
        text-align: center;
    }
    .hire-us-text{
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: 18px;
        letter-spacing: 0.9px;
    }
    .hire-us-lt-title{
        text-align: center !important;
        font-size: 28px !important;
    }
    .hire-us-container{
        background-color: var(--page-second-bg);
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 45px;
        margin-bottom: 0;
    }
    .hire-us-title{
        font-size: 28px !important;
    }
}