@media only screen and (max-width: 1440px) {
    .contact-form-container{
        flex-basis: 40%;
        margin-right: 80px;
    }
    .form-input, .form-area{
        width: 95%;
    }
    .form-input{
        height: 46px;
    }
    .form-area{
        height: 73px;
    }
    .inst-img, .spot-img{
        width: 100%;
        margin-top: 80px;
    }
    .contact-text{
        font-size: 20px;
    }
    .contact-title{
        font-size: 40px;
    }
    .contact-email-text{
        font-size: 28px;
    }
}

@media only screen and (max-width: 1280px) {
    .contact-title-container{
        width: 100%;
    }
    .contact-content-container{
        flex-wrap: wrap;
        justify-content: center;
    }
    .contact-container{
        justify-content: center;
        align-content: center;
    }
    .contact-form-container{
        margin-bottom: 50px;
    }
    .contact-title{
        text-align: center;
    }

    .contact-form-container{
        margin-right: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .contact-img-container{
        justify-content: center;
    }
    .contact-text{
        font-size: 16px;
        letter-spacing: 1.5px;
    }
    .contact-email-text{
        font-size: 24px;
    }
    .contact-title{
        text-align: left;
    }
    .inst-img{
        width: 200px;
    }
    .spot-img{
        width: 200px;
    }
    .contact-form-container{
        margin-bottom: 50px;
        max-width: 100%;
    }
    .form-input{
        max-width: calc(100% - 20px);
    }
    .form-area{
        max-width: calc(100% - 20px);
    }
    .button-lg{
        max-width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .contact-container{
        padding-top: 52px;
        padding-left: 36px;
        padding-right: 36px;
    }
    .inst-img{
        width: 146px;
    }
    .spot-img{
        width: 146px;
    }
    .contact-email-text{
        display: none;
    }
    .contact-text{
        font-size: 11px;
        letter-spacing: 0.45px;
    }
    .contact-email-container{
        margin-bottom: 60px;
    }
    .contact-info-container{
        gap:0;
    }
    .contact-form-container{
        margin-top: 20px !important;
    }
    .contact-email-container-mobile{
        display: inline;
        margin-bottom: 50px;
    }
    .contact-email-text-mobile{
        font-size: 17px;
        letter-spacing: 1.7px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 300;
        color: var(--pink);
        text-align: center;
    }
    .contact-title{
        white-space: nowrap;
        font-size: 26px !important;
    }
    .form-label{
        font-size: 14px;
    }
    .inst-img, .spot-img{
        margin-top: 0;
    }
}

@media only screen and (max-width: 280px) {
    .contact-email-text-mobile{
        font-size: 12px;
    }
    .contact-img-container{
        max-width: 100vw;
    }
    .inst-img{
        width: calc(100% - 30px);
    }
    .spot-img{
        width: calc(100% - 30px);
    }
}