.footer{
    display: flex;
    height: 315px;
    justify-content: center;
    background-color: var(--green-bg);
    flex-direction: column;
    align-items: center;
}

.footer-logo{
    max-width: 158px;
    margin-bottom: 15px;
}

.footer-point-text{
    font-size: 24px;
    color: var(--white);
    letter-spacing: 3px;
    font-family: 'ManhattanRegular';
    margin-bottom: 15px;
}

.footer-studio-text{
    font-size: 9px;
    color: var(--white);
    letter-spacing: 3.6px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500s;
}