@media only screen and (max-width: 1440px){
    .regular-text{
        font-size: 24px;
    }
    .top-container{
        margin-bottom: 180px;   
    }
    .main-text-up, .point-text{
        font-size: 46px !important;
    }
    .main-text-down{
        font-size: 71px !important;
    }
    .main-text-container{
        margin-bottom: 140px    ;
    }
    .studio-text{
        font-size: 18px;
    }
}

@media only screen and (min-width: 767px) and (max-width: 765px){
    .top-container{
        margin-bottom: 150px;
    }
}

@media only screen and (min-width: 1279px) and (max-width: 1280px){
    .top-container{
        margin-bottom: 75px;
    }
}

@media only screen and (max-width: 1024px){
    .top-container{
        margin-bottom: 150px;
    }
    .regular-text{
        font-size: 15px !important;
    }

    .main-text-up{
        font-size: 38px !important;
    }

    .main-text-down{
        font-size: 60px !important;
    }

    .left-text{
        font-size: 17px !important;
    }
}

@media only screen and (max-width: 700px) {

    .regular-text{
        font-size: 11px;
    }

    .left-text{
        font-size: 12px !important;
        letter-spacing: 0;
    }

    .main-text-up{
        font-size: 22px !important;
        letter-spacing: 1.3px;
    }
    .main-text-down{
        font-size: 36px !important;
        text-align: center;
        letter-spacing: 1.3px;
    }

    .logo{
        max-width: 70%;
    }
    
    .main-text-container{
        margin-bottom: 70px;
    }

    .point-text{
        font-size: 26px;
        letter-spacing: 10px;
        width: 171px;
    }

    .studio-text{
        font-size: 15px;
    }
    .title-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }


}

@media only screen and (max-width: 300px) {
    .main-text-up{
        font-size: 20px !important;
    }
    .main-text-down{
        font-size: 35px !important;
        text-align: center;
    }
    .studio-text{
        font-size: 10px;
    }
}

