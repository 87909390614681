@media only screen and (max-width: 1440px) {
    .menu-top-container{
        padding-bottom: 80px;
    }
    .menu-top-text{
        font-size: 24px;
    }
    .menu-stair-text{
        font-size: 50px;
    }
    .menu-stair-second-lvl{
        display: inline-flex;
        transform:translate(-100px, 150px);
    }
    
    .menu-stair-third-lvl{
        display: inline-flex;
        transform: translate(-140px, 150px);
    }
    
    .menu-stair-fourth-lvl{
        display: inline-flex;
        transform: translate(-140px, 150px);
    }
    .menu-section-left{
        top: 200px;
    }
    .menu-left-text{
        font-size: 22px !important;
    }
}

@media only screen and (max-width: 1280px) {
    .menu-rigth-container-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 100%;
        gap: 50px;
    }
    .menu-right-container{
        display: none;
    }
    .menu-left-container{
        display: none;
    }
    .menu-top-container{
        padding-bottom: 70px;
    }
    .menu-stair-text{
        font-size: 52px;
        letter-spacing: 2.6px;
    }
}

@media only screen and (max-width: 1024px) {
    .menu-rigth-container-mobile{
        gap: 35px;
    }
    .menu-stair-text{
        font-size: 40px;
        letter-spacing: 2px;
    }
}

@media only screen and (max-width: 820px) {
    .menu-top-container{
        display: none;
    }
    .menu-rigth-container-mobile{
        gap: 110px;
        align-items: center;
        height: 100vh;
        justify-content: center;
    }
    .menu-stair-text{
        font-size: 52px;
        letter-spacing: 2.6px;
    }
}

@media only screen and (max-width: 700px) {
    .menu-stair-text{
        font-size: 32px;
        letter-spacing: 1.6px;
    }
    .menu-rigth-container-mobile{
        gap: 90px;
    }
}

@media only screen and (max-width: 280px) {
    .menu-stair-text{
        font-size: 20px;
        letter-spacing: 1px;
    }
    .menu-rigth-container-mobile{
        gap: 90px;
    }
}
