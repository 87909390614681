.menu-button{
    width: 102px;
    height: 102px;
    border-radius: 63px;
    border-style: none;
    
    position: fixed;
    top: 62px;
    right: 150px;
    z-index: 4;

    cursor:pointer;
}

.menu-button-open{
    color: var(--pink);
    background-color: var(--white);
}

.menu-button-close{
    background-color: var(--green-bg);
    color: var(--white);
}