.hero-container{
    margin-bottom: 174px;
}

.hero-top-text-container{
    padding-top: 51PX;
    text-align: center;
    margin-bottom: 160px;
}

.hero-top-text{
    font-family: "ManhattanRegular";
    font-size: 26px;
    letter-spacing: 1.3px;
    color: var(--pink)
}

.hero-main-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.hero-logo-container{
    margin-bottom: 47px;
}

.hero-logo{
    width: 464.4px;
}

.hero-title-container{
    text-align: center;
}

.hero-point-text{
    font-family: "ManhattanRegular";
    font-size: 68.4px;
    letter-spacing: 5px;
}

.hero-studio-text{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 27px;
    letter-spacing: 12px;
    color: var(--green);
    margin-bottom: 170px;
}

.hero-subtitle{
    font-family: "ManhattanRegular";
    font-size: 26px;
    letter-spacing: 1.3px;
    color: var(--pink);
}

.hero-left-section{
    position: absolute;
    top: 515px;
    left: -162px;
}

.hero-left-text{
    font-family: "ManhattanRegular";
    font-size: 28px;
    letter-spacing: 1.4px;
    color: var(--green);
}