@media only screen and (max-width: 1440px) {
    .we-are-title{
        font-size: 40px;
    }
    .subtitle{
        font-size: 50px;
    }
}

@media only screen and (max-width: 1280px) {
    .we-are-text{
        z-index: 2;
    }
    .subtitle-container-mobile{
        z-index: 2;
    }

    .subtitle-container{
        z-index: 2;
    }
}

@media only screen and (max-width: 700px) {
    .we-are-container{
        background-color: var(--page-second-bg);
        padding-bottom: 87px;
        padding-top: 59px;
        /* width: 100vw; */
        padding-left: 36px;
        padding-right: 36px;
    }
    .we-are-title{
        font-size: 28px;
        letter-spacing: 1.4px;
        text-align: center;
    }
    .normal-text{
        display: none;
    }
    .subtitle-container-mobile{
        display: block;
    }
    .subtitle-container{
        display: none;
    }
    .subtitle{
        font-size: 30px;
        letter-spacing: 1.5px;
    }
    .we-are-text{
        z-index: 2;
        margin-bottom: 66px !important;
    }
}
