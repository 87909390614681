.menu-container{
    position: fixed;
    top: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: var(--green-bg);
    color: var(--white)
}

.menu-top-container{
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 137px;
}

.menu-top-text{
    font-size: 26px;
    letter-spacing: 1.3px;
    color: var(--white);
    font-family: "ManhattanRegular";
}

.menu-main-container{
    width: 100vw;
    height: 90vh;
    max-height: 90vh;
    display: flex;
    flex-direction: row;
}

.menu-left-container{
    width: 15vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.menu-section-left{
    position: relative;
    top: 330px;
}

.menu-left-text{
    font-size: 28px !important;
    letter-spacing: 1.4px;
    color: var(--white);
    font-family: "ManhattanRegular";
    white-space: nowrap;
}

.menu-stair-text{
    color: var(--white);
    font-size: 70px;
    letter-spacing: 3.5px;
    font-family: "ManhattanRegular";
    white-space: nowrap;
    cursor: pointer;
}

.menu-stair-second-lvl{
    display: inline-flex;
    transform:translate(-100px, 215px);
}

.menu-stair-third-lvl{
    display: inline-flex;
    transform: translate(-220px, 215px);
}

.menu-stair-fourth-lvl{
    display: inline-flex;
    transform: translate(-290px, 215px);
}

.menu-rigth-container-mobile{
    display: none;
}