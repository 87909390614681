@media only screen and (max-width: 1440px) {
    .menu-button{
        width: 90px;
        height: 90px;
        top: 32px;
        right: 60px;
    }
}

@media only screen and (max-width: 820px) {
    .menu-button{   
        top: 30px;
        right: 30px;
    }
}

@media only screen and (max-width: 700px) {
    .menu-button{
        width: 68px;
        height: 68px;
    }
    .menu-icon{
        width: 58%;
    }
}

@media only screen and (max-width: 280px) {
    .menu-button{
        width: 58px;
        height: 58px;

        top: 20px;
        right: 20px;
    }
}
