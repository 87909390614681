.our-work-container{
    margin-bottom: 177px;
}
.our-work-title{
    text-align: left !important;
    margin-bottom: 79px;
}

.carrousel-container{
    width: calc(100vw - 140px);
    margin-bottom: 70px;
}

.carrousel-element{
    /* width: 398px;
    margin-right: 130px; */
}

.work-image{
    width: 399px;
    height: 592px;
    margin-bottom: 30px;
    border-radius: 49% 50% 0% 0% / 38% 37% 46% 45% ;
}

.work-text{
    width: 368px;
    text-align: center;
    font-size: 26px;
    letter-spacing: 1.3px;
    color: var(--green);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.our-work-text-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}

.our-work-digital-text{
    font-size: 18px;
    letter-spacing: 0.9px;
    color: var(--green);
    font-family: "ManhattanRegular";
    display: flex;
    align-content: center;
    align-items: center;
}

.our-work-explore-text{
    font-size: 40px;
    letter-spacing: 2px;
    color: var(--green);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.our-work-title-mb{
    display: none;
}

/* ---------------- */
.break-line::before { content: '\A'; white-space: pre;}

