@media only screen and (max-width: 1440px) {
    .work-top-text-container{
        padding-top: 41px;
    }
    .work-top-text{
        font-size: 22px;
        }
        .work-main-container{
            margin-top: 81px;
            margin-left: 222px;
        }
    .work-img{
        width: 310px;
        height: 394px;
        padding: 15px;
    }
    .work-text-container{
        margin-top: 110px;
    }
    .work-title{
        font-size: 36px;
        margin-bottom: 40px;
    }
    .wotk-subtitle{
        font-size: 19px;
        margin-bottom: 15px;
    }
    .work-description{
        width: 507px;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .work-find-text{
        font-size: 12px;
        margin-bottom: 30px;
    }
    .work-link-text{
        font-size: 18px;
    }
    .work-actions-container{
        padding: 0 80px;
        margin-top: 72px;
    }
    .work-back-action, .work-next-action{
        font-size: 20px;
        gap: 15px;
    }
    .work-left-text{
        font-size: 20px;
    }
    .work-left-section{
        left: -112px;
        /* top: 515px; */
    }

    .only-mc{
        display: flex;
    }
    .only-dk{
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .work-top-text-container{
        padding-top: 31px;
    }
    .work-top-text{
        font-size: 20px;
    }
    .work-main-container{
        margin-top: 70px;
        margin-left: 180px;
    }
    .work-img{
        width: 290px;
        height: 374px;
        padding: 10px;
    }
    .work-text-container{
        margin-top: 100px;
    }
    .work-title{
        font-size: 34px;
        margin-bottom: 30px;
    }
    .wotk-subtitle{
        font-size: 17px;
        margin-bottom: 13px;
    }
    .work-description{
        width: 507px;
        font-size: 12px;
        margin-bottom: 18px;
    }
    .work-find-text{
        font-size: 10px;
        margin-bottom: 15px;
    }
    .work-link-text{
        font-size: 16px;
    }
    .work-actions-container{
        padding: 0 60px;
        margin-top: 32px;
    }
    .work-back-action, .work-next-action{
        font-size: 18px;
        gap: 15px;
    }
    .work-left-text{
        font-size: 16px;
    }
    .work-left-section{
        left: -100px;
        top: 320px;
    }
}

@media only screen and (max-width: 820px) {
    .work-container{
        padding: 0 37px;
    }
    .work-main-container{
        margin-top: 90px;
        margin-left: 0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
    }
    .work-img{
        width: 224px;
        height: 294px;
        border: 0;
        padding: 0;
    }
    .work-text-container{
        margin-top: 20px;
        width: 300px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .work-title{
        display: none;
    }
    .wotk-subtitle{
        font-size: 18px;
        letter-spacing: 0.9px;
        margin-bottom: 19px;
        width: 252px;
        font-weight: 300;
        text-align: center;
    }
    .work-description{
        max-width: 100%;
        font-size: 16px;
        letter-spacing: 0.8px;
        margin-bottom: 18px;
        font-weight: 400;
    }
    .work-find-text{
        font-size: 14px;
        letter-spacing: 0.7px;
        margin-bottom: 15px;
        align-self: self-start;
    }
    .work-link{
        align-self: self-start;
    }
    .work-link-text{
        font-size: 20px;
        letter-spacing: 1px;
    }
    .work-actions-container{
        padding: 0;
        justify-content: end;
    }
    .work-back-action{
        display: none;
    }
    .work-next-action{
        font-size: 20px;
        letter-spacing: 1px;
        white-space: nowrap;
        gap: 10px;
    }

    .work-left-section{
        display: none;
    }
}

@media only screen and (max-height: 896px ) and (max-width: 1920px) {
    .work-top-text-container{
        padding-top: 41px;
    }
    .work-top-text{
        font-size: 22px;
    }
    .work-main-container{
        margin-top: 81px;
        margin-left: 222px;
    }
    .work-img{
        width: 386px;
        height: 520px;
    }
    .work-text-container{
        margin-top: 130px;
    }
    .work-title{
        margin-bottom: 40px;
    }
    .work-left-text{
        font-size: 24px;
    }
    .work-left-section{
        left: -132px;
        top: 415px;
    }
}