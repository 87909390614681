.contact-container{
    display: flex;
    flex-direction: column;
}

.contact-title-container{
    width: 50%;
}

.contact-title{
    text-align: left;
}

.contact-content-container{
    display: flex;
    flex-direction: row;
}

.contact-form-container{
    flex-basis: 50%;
    margin-top: 90px;
}

.form-label{
    color: var(--pink);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 1.7px;
}

.form-input{
    width: 654px;
    height: 61px;
    border: 1px solid var(--green-bg);
    border-radius: 4px;
    margin-top: 11px;
    margin-bottom: 11px;
    font-size: 17px;
    color: var(--green-font-input);
    padding-left: 20px;
    font-family: 'Montserrat', sans-serif;
    background-color: var(--page-bg);
}

.form-area{
    margin-top: 11px;
    width: 654px;
    height: 88px;
    border: 1px solid var(--green-bg);
    border-radius: 4px;
    resize: none;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 26px;
    font-size: 14px;
    color: var(--green-font-input);
    font-family: 'Montserrat', sans-serif;
    background-color: var(--page-bg);
}

.contact-info-container{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 45px;
}

.contact-img-container{
    display: flex;
    flex-direction: row;
}

.inst-container{
    margin-right: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.spot-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.contact-email-container{
    margin-bottom: 109px;
}

.contact-email-container-mobile{
    display: none;
}

.contact-email-text{
    font-size: 38px;
    letter-spacing: 3.8px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: var(--pink);
    text-align: center;
}

.contact-text{
    color: var(--green);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 26px;
    letter-spacing: 1.3px;
}