@media only screen and (max-width: 1440px) {
    .footer{
        height: 250px;
    }
}

@media only screen and (max-width: 700px) {
    .footer{
        height: 137px;
    }
    .footer-logo{
        max-width: 71px;
    }
    .footer-point-text{
        font-size: 11px;
    }
    .footer-studio-text{
        font-size: 4px;
    }
}