.landing-container{
    background-color: var(--page-bg);
    min-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    overflow-x: hidden;
}

.landing-content{
    padding-left: 140px;
    padding-right: 140px;
}