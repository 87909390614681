@media only screen and (max-width: 1440px) {
    .clients-title-container{
        margin-bottom: 100px;
    }
    .client-img{
        max-width: 210px;
        max-height: 120px;
    }
    .clients-first-row{
        margin-left: 0;
        margin-right: 0;
        column-gap: 135px;
        margin-bottom: 70px;
    }
    .clients-second-row{
        margin-left: 0;
        margin-right: 0;
        column-gap: 135px;
        margin-bottom: 90px;
    }
    .clients-third-row{
        margin-left: 200px;
        margin-right: 200px;
        column-gap: 157.5px;
    }
}

@media only screen and (max-width: 1280px) {
    .clients-main-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 100px;
    }
    .only-dk-cl{
        display: none;
    }
    .only-mb{
        display: flex;
    }
}

@media only screen and (max-width: 1024px) {
    .client-img{
        max-width: 300px;
    }
    .clients-main-container{
        column-gap: 50px;
    }
    .client-image-div{
        flex-basis: 40%;
    }
}

@media only screen and (max-width: 820px) {
    .client-img{
        max-width: 200px;
    }
}

@media only screen and (max-width: 700px) {
    .clients-container{
        padding-top: 51px;
        padding-left: 36px;
        padding-right: 36px;
    }
    .clients-main-container {
        margin-bottom: 45px;
    }
    .client-img{
        max-width: 140px;
        max-height: 66px;
    }
    .clients-main-container{
        column-gap: 0;
        row-gap: 44px;
    }
    .clients-title-container{
        margin-bottom: 80px;
    }
    .client-image-div{
        justify-content: center;
    }
}

@media only screen and (max-width: 280px) {
    .clients-main-container{
        justify-content: center;
    }
}