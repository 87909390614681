@media only screen and (max-width: 1440px) {
    .work-image{
        width: 280px;
        height: 362px; 
    }
    .work-text{
        width: 280px;
        font-size: 20px;
    }
    .carrousel-container{
        margin-bottom: 80px;
    }
    .our-work-title{
        margin-bottom: 60px;
    }
    .our-work-digital-text{
        font-size: 15px;
    }
    .our-work-explore-text{
        font-size: 35px;
    }
}

@media only screen and (max-width: 820px) {
    .our-work-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .our-work-title-mb{
        display: inline;
        margin-bottom: 61px;
        text-align: center !important;
        width: calc(100vw - 280px);
    }
    .our-work-title-dk{
        display: none;
    }
    .our-work-explore-text{
        font-size: 24px;
        letter-spacing: 1px;
    }
    .our-work-digital-text{
        font-size: 18px;
        letter-spacing: 0.7px;
    }

    .work-text{
        font-size: 16px;
        letter-spacing: 0.8px;
        white-space: normal;
    }
    .our-work-text-container{
        flex-direction: column-reverse;
        gap: 65px;
        align-items: center;
    }
    .carrousel-container{
        margin-bottom: 100px;
    }
}
@media only screen and (max-width: 800px) {
    .carrousel-element{
        margin-right: 40px;
    }
}

@media only screen and (max-width: 700px) {
    .our-work-container{
        background-color: var(--page-second-bg);
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 66px;
        padding-bottom: 46px;
        margin-bottom: 0;
    }
    .our-work-explore-text{
        font-size: 20px;
        letter-spacing: 1px;
    }
    .our-work-digital-text{
        font-size: 14px;
        letter-spacing: 0.7px;
    }
    .work-image{
        width: 224px;
        height: 294px; 
    }
    .work-text{
        width: 224px;
    }
    .our-work-title-mb{
        width: 100%;
    }
    .carrousel-container{
        width: calc(100vw - 70px);
        margin-bottom: 60px;
        
    }.our-work-title-mb{
        margin-bottom: 70px;
    }

    .our-work-text-container{
        gap: 60px;
    }
}