@media only screen and (max-width: 1920px){
    .service-stair-text{
        white-space: nowrap;
    }
    .stair-second-lvl{
        transform:translate(-95px, 150px);
        max-width: 10%;
    }
    .stair-third-lvl{
        transform: translate(-95px, 150px);
        max-width: 10%;
    }
    
    .stair-fourth-lvl{
        transform: translate(-95px, 150px);
        max-width: 10%;
    }
    
    .stair-fifth-lvl{
        transform: translate(-90px, 150px);
        max-width: 10%;
    }
}

@media only screen and (max-width: 1440px){
    .text-container{
        margin-bottom: 65px;
    }
    .service-stair-text{
        font-size: 30px;
        letter-spacing: 1.5px;
    }
    .stair-second-lvl{
        transform:translate(-80px, 110px);
    }
    .stair-third-lvl{
        transform: translate(-80px, 110px);
    }
    
    .stair-fourth-lvl{
        transform: translate(-50px, 110px);
    }
    
    .stair-fifth-lvl{
        transform: translate(-40px, 110px);
    }
    .stair-container{
        margin-bottom: 142px;
    }
}

@media only screen and (max-width: 1280px) {
    .text-container{
        text-align: center;
    }
    .services-title{
        text-align: center;
    }
    .services-container{
        height: auto;
    }

    .stair-container{
        display: none;
    }
    
    .stair-container-mobile{
        display: flex;
        margin-bottom: 114px;
        flex-direction: column;
        text-align: center;
        gap: 53px;
    }

}

@media only screen and (max-width: 700px) {
    .services-container{
        padding-top: 55px;
        padding-left: 36px;
        padding-right: 36px;
        padding-bottom: 45px;
    }
    .service-stair-text{
        font-size: 22px;
        letter-spacing: 1.1px;
    }
    .services-subtitle{
        font-size: 16px;
        letter-spacing: 0.8px;
    }
    .service-span{
        margin-left: -5px;
    }
    .text-container {
        margin-bottom: 80px;
    }
    .stair-container-mobile{
        margin-bottom: 0px;
    }
}

