.services-container{
    display: flex;
    flex-direction: column;
    height: 1000px;
}

.text-container{
    margin-bottom: 79px;
}

.services-title{
    text-align: left;
    margin-bottom: 10px;
}

.services-subtitle{
    font-size: 40px;
    letter-spacing: 2px;
    color: var(--pink);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.stair-container{
    margin-bottom: 192px;
}

.stair-container-mobile{
    display: none;
}

.service-stair-text{
    color: var(--green-bg);
    font-size: 40px;
    letter-spacing: 2.2px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    text-transform: uppercase;
}

.first-stair-service:hover, .second-stair-service:hover, .third-stair-service:hover, .fourth-stair-service:hover, .fifth-stair-service:hover {
    /*  text-decoration: underline; */
}

.service-span{
    margin-left: -15px;
}

.stair-second-lvl{
    display: inline-flex;
    transform:translate(-30px, 124px);
}

.stair-third-lvl{
    display: inline-flex;
    transform: translate(-30px, 124px);
}

.stair-fourth-lvl{
    display: inline-flex;
    transform: translate(-30px, 124px);
}

.stair-fifth-lvl{
    display: inline-flex;
    transform: translate(-30px, 124px);
}

/* .stair {
    font-size: 44px;
    margin-top:30px;
  }

span {
display: inline-block;
transform:translate(30px, 97px);
    
} */