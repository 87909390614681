.looking-for-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 148px;
}

.looking-for-title{
    margin-bottom: 138px;
    white-space: nowrap;
}

.looking-for-text-container{
    padding-top: 120px;
}

.looking-for-text{
    font-size: 50px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    letter-spacing: 2.5px;
    color: var(--pink);
    max-width: 959px;
}

.looking-for-img-container{
    padding-top: 10px;
}

.looking-for-img{
    border: 2px solid var(--pink);
    border-radius: 49% 50% 0% 0% / 30% 31% 46% 45% ;
    padding: 20px;
    /* width: 568px;
    height: 961px; */
    width: 539.6px;
    height: 912.95px;
}
.lf-mb-points{
    display: none;
}
.looking-for-text-mb{
    display: none;
}

.looking-for-lets-talk-text{
    cursor: pointer;
}