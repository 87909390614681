.work-container{
    background-color: var(--page-bg);
    min-height: 100vh;
    max-width: 100vw;
}

.work-top-text-container{
    padding-top: 51PX;
    text-align: center;
}

.work-main-container{
    margin-top: 110px;
    margin-left: 262px;

    display: flex;
    flex-direction: row;
    gap: 60px;
}

.work-img{
    width: 466px;
    height: 600px;
    border-radius: 49% 50% 0% 0% / 38% 37% 46% 45% ;

    border: 2px solid var(--pink);
    border-radius: 49% 50% 0% 0% / 35% 35% 46% 45% ;
    padding: 20px;
}

.work-text-container{
    margin-top: 160px;
}

.work-title{
    margin-bottom: 50px;
    text-align: left;
}

.wotk-subtitle{
    font-size: 26px;
    letter-spacing: 1.3px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: var(--green);
    margin-bottom: 20px;
}

.work-description{
    width: 507px;
    font-size: 22px;
    letter-spacing: 1.1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: var(--green);
    margin-bottom: 20px;
}

.work-find-text{
    font-size: 20px;
    letter-spacing: 1px;
    color: var(--pink);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin-bottom: 50px;
}

.work-link{
    display: flex;
    align-items: center;
    gap: 25px;
}

.work-link-icon{
    color: var(--green)
}

.work-link-text{
    font-size: 20px;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: var(--pink)
}

.work-actions-container{
    display: flex;
    justify-content: space-between;
    padding: 0 120px;
    margin-top: 100px;
}

.work-back-action, .work-next-action{
    font-size: 40px;
    letter-spacing: 2px;
    color: var(--green);
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    
    display: flex;
    align-items: center;
    gap: 30px;

    cursor: pointer;
}
 .only-mc{
     display: none;
 }