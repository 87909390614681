@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

@font-face {
    font-family: "ManhattanRegular";
    src: local("ManhattanRegular"),
     url("../fonts/ManhattanRegular.ttf") format("truetype");
    font-weight: bold;
}

:root {
    --page-bg: #F7F3F2;
    --page-second-bg: #EAE0DE;
    --green: #767260;
    --pink: #9D7876;
    --white: #FFFFFF;
    --green-bg: #7C7E63;
    --green-font-input: #575C59;
    --menu-green-text: #A3A48C;
  }

p{
    margin: 0;
}

a {
    text-decoration: none;
}

.text-rotate{
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.padding-top-100{
    padding-top: 100px;
}

.padding-bottom-100{
    padding-bottom: 100px;
}

.margin-top-100{
    margin-top: 100px;
}

.margin-bottom-100{
    margin-bottom: 100px;
}

.text-align-center{
    text-align: center !important;
}

.text-align-left{
    text-align: left !important;
}

.title{
    color: var(--green);
    font-size: 56px;
    letter-spacing: 8.4px;
    font-family: 'ManhattanRegular';
    text-align: center;
}

.green-button{
    background-color: var(--green-bg);
    color: var(--white);
    border-radius: 37px;
    height: 73px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 40px;
    letter-spacing: 2px;
    border-style: none;
    cursor: pointer;
}

.button-md {
    width: 398px !important;
}

.button-lg{
    width: 674px !important;
}