.clients-title-container{
    margin-bottom: 160px;
}

.clients-main-container{
    margin-bottom: 178px;
}

.clients-first-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 150px;
    margin-bottom: 106px;
    margin-left: 122px;
    margin-right: 122px;
}

.clients-second-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 150px;
    margin-bottom: 120px;
    margin-left: 122px;
    margin-right: 122px;
}

.clients-third-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 175px;
    margin-left: 422px;
    margin-right: 280px;
}

.only-mb{
    display: none;
}

.clients-mobile-row{
    display: flex;
}

.client-image-div{
    display: flex;
    justify-content: start;
    flex-basis: 50%;
}