@media only screen and (max-width: 1440px) {
    .green-button{
        height: 60px;
        font-size: 36px;
    }
    .button-md {
        width: 358.2px !important;
    }
    
    .button-lg{
        width: 550px !important;
    }
    .margin-bottom-100{
        margin-bottom: 80px;
    }
    .title{
        font-size: 50px;
        letter-spacing: 8.4px;
        font-family: 'ManhattanRegular';
    }
}

@media only screen and (max-width: 700px) {
    .title{
        font-size: 28px;
        letter-spacing: 4.2px;
        text-align: center;
    }

    .green-button{
        font-size: 22px;
        letter-spacing: 1.1px;
        height: 48px;
    }

    .button-md {
        width: 100% !important;
    }

    .no-margin-mobile{
        margin: 0 !important;
    }

}