@media only screen and (max-width: 1440px) {
    .hero-logo{
        width: 300px;
    }
    .hero-top-text-container{
        margin-bottom: 95px;
    }
    .hero-point-text{
        font-family: "ManhattanRegular";
        font-size: 40px;
    }

    .hero-studio-text{
        font-size: 20px;
        margin-bottom: 95px;
    }
    .hero-left-text{
        font-size: 23px;
    }
    .hero-left-section{
        top: 350px;
        left: -162px;
    }
}

@media only screen and (max-width: 700px) {
    .hero-logo{
        width: 235px;
    }

    .hero-point-text{
        font-family: "ManhattanRegular";
        font-size: 25px;
    }

    .hero-studio-text{
        font-size: 12px;
        letter-spacing: 4.8px;
        margin-bottom: 200px;
    }
    .hero-subtitle{
        font-size: 16px;
        letter-spacing: 0.8px;
    }
    .hero-left-text{
        display: none;
    }
    .hero-container{
        margin-bottom: 0px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .hero-top-text-container{
        padding-top: 0;
    }
    .hero-top-text{
        font-size: 16px;
        letter-spacing: 0.8px;
    }
}